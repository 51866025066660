import React from 'react';
import SliderImg from '../assets/images/slider3.jpg';

const Slider = ({ handleScroll }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-md-5">
          <h1 className='bold mb-3 pt-5 mt-5'>ВАШИ ПЕРЕВОДЧИКИ РУССКОГО ЯЗЫКА В ГЕРМАНИИ</h1>
          <p className='f-20'>Вам или Вашей фирме <strong>нужен качественный перевод</strong> документов, текстов или сайтов? Мы, переводчики с профильным образованием, <strong>переводим больше 20 лет качественно</strong>, быстро, с учетом индивидульных запросов каждого клиента!</p>
          <div><button className='btn btn-primary btn-custom w-50 mt-3' onClick={handleScroll}>Связаться</button></div>
        </div>
        <div className="col-xs-12 col-md-7">
          <img src={SliderImg} alt='' width='100%' />
        </div>
      </div>
    </div>
  );
};

export default Slider;
