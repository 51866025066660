import React from 'react';
import Concept from '../assets/images/concept.jpg';
import Interview from '../assets/images/interview.jpg';
import Quality from '../assets/images/quality.jpg';

const Advantages = () => {
  return (
    <div className="container">
      <h2 className='text-center'>НАШИ ПРЕИМУЩЕСТВА</h2>
      <div className='row mt-5'>
        <div className='col-md-6'>
          <h4 className='text-start bold'>ОПЫТ и ДОВЕРИЕ</h4>
          <img src={Concept} alt='НАШИ ПРЕИМУЩЕСТВА' width='60%' />
        </div>
        <div className='col-md-6'>
          <ul>
            <li><p>Мы переводим с <strong>2003 года</strong>, а бюро переводов Russisch-Übersetzer.EU создали и официально зарегистрировали в Германии в <strong>2013 году</strong>.</p></li>
            <li>Название Russisch-Übersetzer.EU в переводе с немецкого <strong>означает "Переводчики русского в ЕС"</strong>: мы оказываем услуги как в Германии, так и по всему ЕС.</li>
            <li>Среди наших клиентов – как <strong>частные лица</strong>, так и <strong>фирмы, государственные структуры</strong>, неправительственные организации.</li>
            <li>У нас больше <strong>1000 довольных клиентов</strong>.</li>
            <li><strong>Индивидуальный подход к каждому клиенту и доверие</strong> – высшая ценность нашего Бюро переводов.</li>
          </ul>
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col-md-6'>
          <h4 className='text-start bold'>ПРОФЕССИОНАЛИЗМ</h4>
          <img src={Interview} alt='ПРОФЕССИОНАЛИЗМ' width='60%' />
        </div>
        <div className='col-md-6'>
          <ul>
            <li>Мы, Лада Янсен и Татьяна Истомина, <strong>профессиональные переводчики</strong> в Германии, в Эссене и Лангене (между г. Франкфурт-на-Майне и г. Дармштадт).</li>
            <li>
              <strong>Татьяна Истомина</strong> – магистр перевода и права (высшие образования в области права, перевода и лингвистики в РФ, Германии, Великобритании).<br/>
              <a href='https://www.google.com/search?client=opera&cs=0&output=search&q=Russisch-Übersetzer.EU:+Übersetzungsbüro+%26+Übersetzerkurse+(Russisch,+Deutsch)&ludocid=16912495184709979125&gsas=1&client=opera&lsig=AB86z5UBHyNHSVDZVPjsBx3U_Wxq&sa=X&ved=2ahUKEwi3kqfihrH9AhXjgv0HHV5uAhQQj9IGKAB6BAgeEAE&biw=1240&bih=571&dpr=1.5#lrd=0x47bd730d2b74b101:0xeab540c3a6248bf5,1' 
                target='_blank' rel="noreferrer"
              >Отзывы</a>  
            </li>
            <li>
              <strong>Лада Янсен</strong> – дипломированный переводчик (высшее образование в области перевода и лингвистики, РФ, Германия). <br/>
              <a href='https://www.google.com/search?q=russisch-übersetzer.eu%3A+essen&client=opera&hs=lwL&biw=1240&bih=571&sxsrf=AJOqlzVRFQrMrpJscGcGp4GhvhuEodKPDA%3A1677341456063&ei=EDP6Y7imA6eF9u8P_96-qAs&ved=0ahUKEwi47p7th7H9AhWngv0HHX-vD7UQ4dUDCA4&uact=5&oq=russisch-übersetzer.eu%3A+essen&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIGCAAQFhAeOggIABCiBBCwAzoHCAAQDRCABDoKCAAQDRCABBDJA0oECEEYAVDdBFiEC2CoDWgCcAB4AIAB8gKIAbsGkgEHMi4yLjAuMZgBAKABAcgBBMABAQ&sclient=gws-wiz-serp#lrd=0x47b8c2d2d3042a7f:0x68dd84d128c3971f,1' 
                target='_blank' rel="noreferrer"
              >Отзывы</a>
            </li>
            <li>Мы <strong>регулярно</strong> проходим программы для дополнительного образования профессионалов, чтобы быть в курсе изменений в отрасли.</li>
            <li>Мы <strong>публикуем статьи и проводим обучение для коллег</strong>, чтобы передать опыт.</li>
          </ul>
        </div>
      </div>
      <div className='row mt-5'>
        <div className='col-md-6'>
          <h4 className='text-start bold'>КАЧЕСТВО И СПЕЦИАЛИЗАЦИЯ</h4>
          <img src={Quality} alt='КАЧЕСТВО И СПЕЦИАЛИЗАЦИЯ' width='60%' />
        </div>
        <div className='col-md-6'>
          <ul>
            <li>Наша специализация – <strong>юридическая и медицинская</strong> тематика. Мы мастерски владеем <strong>узкой терминологией</strong>. 
                Это позволяет нам переводить быстрее и без ошибок, по сравнению с переводчиками широкого профиля, а нашим клиентам экономить время и деньги.
            </li>
            <li>Быстро мы переводим тексты и документы любой сложности и в любой срок, гарантируя <strong>высшее качество перевода</strong>.</li>
            <li>Мы используем <strong>двойную систему проверки</strong> перевода – все переводы проверяет носитель языка и система проверки текстов искуственным интеллектом.</li>
            <li>Мы работаем с разными форматами текстов – от таблиц до текстов. При необходимости делаем несколько копий переводов, в удобных для клиента форматах (Гугл-документы, Ворд и тд.)</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Advantages;
