import React from 'react';
import Info from '../assets/images/info.svg';
import Phone from '../assets/images/phone.svg';
import Email from '../assets/images/email.svg';
import Facebook from '../assets/images/facebook.svg';
import Linkedin from '../assets/images/linkedin.svg';
import Copyright from '../assets/images/copyright.svg';
import Xing from '../assets/images/xing.svg';
import VK from '../assets/images/vk.svg';

const Footer = () => {
  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-8'>
          <div className='row mb-3'>
            <div className='col-md-1'>
              <img src={Info} alt='' width='100%' style={{ maxWidth: '32px' }} />
            </div>
            <div className='col-md-11 f-12'>
              <h4>IMPRESSUM</h4>
              <p>Russisch-übersetzer.EU (*перевод с немецкого "Русские-переводчики в ЕС") <br/> Профессиональные русские переводчики в Германии</p>
              <p>Tatiana Istomina, Annastr.13, 63225 Langen (Германия) Телефон: +49 160 7631957</p>
              <p>Lada Jansen, Leopold-Simon-Str. 36, 45239 Essen (Германия) Телефон: +49 201 408 8304</p>
              <p>Используемые на сайте иконки взяты с сайта https://icons.getbootstrap.com и <br/>используются на условиях лицензии MIT.</p>
              <p><a href="https://www.freepik.com/free-vector/language-word-concept-with-flat-design_2581412.htm#query=languages&position=23&from_view=search&track=sph">Image by Freepik</a></p>
              <p><a href="https://www.freepik.com/free-vector/reviews-concept-landing-page_4660575.htm#query=experience&position=42&from_view=search&track=sph">Image 2 by pikisuperstar</a> on Freepik</p>
              <p><a href="https://www.freepik.com/free-vector/job-interview-conversation_9650007.htm#query=professional&position=27&from_view=search&track=sph">Image 3 by pch.vector</a> on Freepik</p>
              <p><a href="https://www.freepik.com/free-vector/illustration-quality-control_2806721.htm#query=quality&position=4&from_view=search&track=sph">Image 4 by rawpixel.com</a> on Freepik</p>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <p><img src={Copyright} alt='Copyright' width='14px' className='me-1'/>Все права защищены.</p>
          <p className='mt-2'><img src={Phone} alt='Phone' /> +49 160 763 1957</p>
          <p className='mt-2'><img src={Email} alt='Email' /> info@russisch-uebersetzer.eu</p>
          <p className='mt-2 d-flex gap-2'>
            <a href='https://www.facebook.com/Russischuebersetzer.EU'><img src={Facebook} alt='Facebook' width='32' height='32' /></a>
            <a href='https://www.linkedin.com/company-beta/18107423/'><img src={Linkedin} alt='Linkedin' width='32' height='32' /></a>
            <a href='https://vk.com/russisch_uebersetzer'><img src={VK} alt='VK' width='32' height='32' /></a>
            <a href='https://www.xing.com/xbp/pages/russisch-uebersetzer-eu'><img src={Xing} alt='Xing' width='32' height='32' /></a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
