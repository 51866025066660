/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react';
import Idea from '../assets/images/idea.svg';

const Contact = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [msg, setMsg] = useState('');
  const [confirm, setConfirm] = useState(false);

  const handleForm = (e) => {
    e.preventDefault();

    const url = 'send.php';

    const formData = new FormData();

    formData.append('email', email);
    formData.append('name', name);
    formData.append('msg', msg);

    fetch(url, { method: 'POST', body: formData })
    .then(function (res) {
      console.log(res);
      if(res.status === 200) {
        alert('Ваше сообщение отправлено!');
      } else {
        alert('Что-то пошло не так, повторите позже!');
      }
    })
    .then(function (body) {
      console.log(body);      
      setEmail('');
      setName('');
      setMsg('');
      setConfirm(!confirm);
    });
  }

  return (
    <div className='container'>
      <h2 className='text-center mt-3'>СВЯЖИТЕСЬ С НАМИ</h2>
      <p className='text-center bold'>Мы на связи 24/7</p>
      <p className='text-center'><a href="mailto:nfo@russisch-uebersetzer.eu">info@russisch-uebersetzer.eu</a></p>
      <div className='row gx-5 mt-5'>
        <div className='col-md-4 mb-5'>
          <form onSubmit={handleForm} method='post' action='send.php'>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">Введите ваш email</label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">Введите ваше имя</label>
              <input
                type="text"
                className="form-control" 
                id="name" 
                name="name"
                value={name} 
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">Сообщение</label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                rows="3" 
                value={msg} 
                onChange={(e) => setMsg(e.target.value)}
              ></textarea>
            </div>
            <div className="form-check">
              <input className="form-check-input"
                type="checkbox"
                name="confirm"
                id="confirm"
                value={confirm}
                onChange={(e) => setConfirm(!confirm) }
              />
              <label className="form-check-label" htmlFor="confirm">
                Я прочитал/а и принимаю <br />
                <a href="https://docs.google.com/document/d/1YImFq36us0M-xI1oA7_x9371sOZp8iDSU9nGWWbU6Io/edit?usp=sharing">Политика конфиденциальности»</a>
              </label>
            </div>
            <button
              type='submit'
              className='btn btn-primary w-100 mt-3'
              disabled={!email || !name || !msg || !confirm}
            >Отправить</button>
          </form>
        </div>
        <div className='col-md-8'>
          <h4 className='text-center mb-3'>НАШИ ОФИСЫ</h4>
          <div className='row mt-3'>
            <div className='col-md-6 text-start d-flex align-items-start'>
              <img src={Idea} alt='Idea' width='25px' />
              <div className='ms-3'>
                <div className='bold'>ФРАНКФУРТ (Ланген)</div>
                <div>ТАТЬЯНА ИСТОМИНА</div>
                <div>Annastr.13, 63225 Langen Германия</div>
                <div>Телефон: +49 6103 509 35 82</div>
                <div>Мобильный / WhatsApp: +49 160 76 319 57</div>
                <div>E-mail: istomina@russisch-uebersetzer.eu</div>
              </div>
            </div>
            <div className='col-md-6 mt-3'>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2565.0136148810298!2d8.6640185!3d49.9923542!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bd730d2b74b101%3A0xeab540c3a6248bf5!2sRussisch-%C3%9Cbersetzer.EU%3A%20%C3%9Cbersetzungsb%C3%BCro%20%26%20%C3%9Cbersetzerkurse%20(Russisch%2C%20Deutsch)!5e0!3m2!1sru!2scz!4v1677502443187!5m2!1sru!2scz" 
                width="100%" 
                height="auto"
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-md-6 text-start d-flex align-items-start'>
              <img src={Idea} alt='Idea' width='25px' />
              <div className='ms-3'>
                <div className='bold'>ЭССЕН</div>
                <div>ЛАДА ЯНСЕН</div>
                <div>Leopold-Simon-Str. 36, 45239 Essen Германия</div>
                <div>Телефон: +49 201 408 8304</div>
                <div>Mobile Phone / WhatsApp: +49 157/75 372 401</div> 
                <div>E-mail: jansen @russisch-uebersetzer.eu</div>
              </div>
            </div>
            <div className='col-md-6 mt-3'>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2490.122914120907!2d6.9885467127129965!3d51.382418971667356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8c4afa57a0793%3A0xa551469711e93a38!2zTGVvcG9sZC1TaW1vbi1TdHJhw59lIDM2LCA0NTIzOSBFc3Nlbiwg0JPQtdGA0LzQsNC90LjRjw!5e0!3m2!1sru!2scz!4v1682833297422!5m2!1sru!2scz"
                width="100%"
                height="auto"
                loading="lazy" 
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
