import React from 'react';
import Price from '../assets/images/price.svg';
import Condition from '../assets/images/condition.svg';
import Payment from '../assets/images/payment.svg';

const Offer = () => {
  return (
    <div className='container'>
      <h2 className='text-center'>НАШИ УСЛОВИЯ</h2>
      <div className='row text-center gx-5 mt-5'>
        <div className='col-md-4'>
          <img src={Price} alt='Price' width="32" height="32"/>
          <h4 className='my-3'>ЦЕНЫ</h4>
          <ul className='text-start'>
            <li>Цена за 1 строку текста (55 знаков с пробелами) составляет от 1 евро.</li>
            <li>Цена за 1 час устного последовательного перевода составляет от 75 евро.</li>
            <li>Для того, чтобы рассчитать стоимость Вашего текста, документа или сайта и обсудить условия, свяжитесь с нами.</li>
          </ul>
        </div>
        <div className='col-md-4'>
          <img src={Payment} alt='Payment' width="32" height="32"/>
          <h4 className='my-3'>ОПЛАТА</h4>
          <ul className='text-start'>
            <li>Оплата переводом на банковский счет в Германии или PayPal. Возможен безналичный расчет в Германии.</li>
            <li>100% предоплата, возможна оплата на месте при получении перевода.</li>
            <li>Для того, чтобы рассчитать стоимость Вашего текста, документа или сайта и обсудить условия, свяжитесь с нами.</li>
          </ul>
        </div>
        <div className='col-md-4'>
          <img src={Condition} alt='Condition' width="32" height="32"/>
          <h4 className='my-3'>УСЛОВИЯ</h4>
          <ul className='text-start'>
            <li>Срок выполнения  перевода и его цена зависят от объема и срочности, а также загруженности переводчика, в среднем составляет от 2-3х дней до недели, при больших объемах - от недели.</li>
            <li>Перевод можно забрать лично в офисе Франкфурта (Ланген) или Эссена. По почте отправляем по всему миру, если необходимо, срочной почтой.  Почтовые расходы добавляем к стоимости перевода.</li>
            <li>По запросу делаем несколько копий перевода.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Offer;
