import React from 'react';
import Logo from '../assets/images/logo2.jpeg';
import Facebook from '../assets/images/facebook.svg';
import Linkedin from '../assets/images/linkedin.svg';
import Xing from '../assets/images/xing.svg';
import VK from '../assets/images/vk.svg';

const Nav = ({ handleScroll }) => {
  return (
    <div className="container">
      <header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom">
        <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
          <img src={Logo} alt='logo' height='50px' width='auto' />
        </a>
        <ul className="nav nav-pills">
          <li className="nav-item me-2">
            <a href='https://www.xing.com/xbp/pages/russisch-uebersetzer-eu'><img src={Xing} alt='Xing' width='39' height='39' /></a>
          </li>
          <li className="nav-item me-2">
            <a href='https://www.facebook.com/Russischuebersetzer.EU'><img src={Facebook} alt='Facebook' width='39' height='39' /></a>
          </li>
          <li className="nav-item me-2">
            <a href='https://www.linkedin.com/company-beta/18107423/'><img src={Linkedin} alt='Linkedin' width='39' height='39' /></a>
          </li>
          <li className="nav-item me-2">
            <a href='https://vk.com/russisch_uebersetzer'><img src={VK} alt='VK' width='39' height='39' /></a>
          </li>
          <li className="nav-item">
            <button className='btn btn-outline-primary me-2' onClick={handleScroll}>Связаться</button>
          </li>
        </ul>
      </header>
    </div>
  );
};

export default Nav;
