import React from 'react';
import './App.css';
import Nav from './components/Nav';
import Slider from './components/Slider';
import Advantages from './components/Advantages';
import Offer from './components/Offer';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  const onScroll = () => {
    window.scroll({
      top: document.body.offsetHeight,
      left: 0, 
      behavior: 'smooth',
    });
  }

  return (
    <>
      <Nav handleScroll={onScroll} />
      <Slider handleScroll={onScroll} />
      <section className='p-5'><Advantages /></section>
      <section className='bg p-5'><Offer /></section>
      <section className='p-5 slider'></section>
      <section className='bg p-5'><Contact /></section>
      <section className='p-5'><Footer /></section>
    </>
  );
}

export default App;
